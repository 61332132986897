
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$fanmire-web-platform-primary: mat.define-palette(mat.$indigo-palette);
$fanmire-web-platform-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$fanmire-web-platform-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$fanmire-web-platform-theme: mat.define-light-theme((
  color: (
    primary: $fanmire-web-platform-primary,
    accent: $fanmire-web-platform-accent,
    warn: $fanmire-web-platform-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($fanmire-web-platform-theme);

$enable-rounded: true !default;
$enable-shadows: true !default;

$font-size-base: 1rem !default;

@import 'colors.scss';

$input-font-size: 16px;
$card-border-radius: 0.5rem !default;

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 765px,
  xl: 860px) !default;
 

$font-family-sans-serif: "Poppins-Regular" -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;

$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;

$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-base;

$font-weight-lighter: 200 !default;
$font-weight-light  : 300 !default;
$font-weight-normal : normal !default;
$font-weight-bold   : 600 !default;
$font-weight-bolder : bolder !default;

$headings-font-weight: bold;

$form-feedback-valid-color       : $success;
$form-feedback-invalid-color     : $danger;
$form-feedback-icon-valid-color  : $success;
$form-feedback-icon-invalid-color: $danger;

$form-feedback-icon-valid  : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: ("ng-valid": ("color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid),
  "ng-invalid": ("color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid));
$form-validation-states: map-merge(("valid": ("color": $form-feedback-valid-color,
      "icon": $form-feedback-icon-valid),
    "invalid": ("color": $form-feedback-invalid-color,
      "icon": $form-feedback-icon-invalid),
  ),
  $form-validation-states);

@import '../assets/fonts/font';  
@import '../../node_modules/bootstrap/scss/bootstrap.scss';  
@import 'custom_scrollbar.scss';
@import 'sidebar.scss'; 
@import 'video_js';  
@import 'buttons.scss'; 
@import 'text.scss';
@import 'image.scss';
@import 'grids.scss';

.fmpurple-text { 
  color:  $purple;
}

.fmpurple-bg { 
  background-color:  $purple;
}

.fmpurple-border { 
  border: 1px solid $purple;
}

.fmdeepgray-text {
  color:  $fmdeepgray;
}

.thick-border{
  border-width: 3px !important;
}

.clickable {
  cursor: pointer;
  pointer-events: all;
}

.clickable[disabled] {
  cursor: not-allowed;
  pointer-events: none;
}

/** Link Styles **/
a { 
  font-weight: 300;

  &.text-dark{
    text-decoration: none !important;

    &:hover { 
      p {
        font-weight: bolder !important; 
      }    
      
      span.hoverbold{
        font-weight: 600 !important; 
      }
    }
  }
}

.link-text {
  color: #0b57d0 !important; 
}
/** End Link Styles **/

/** Footer Styles **/
footer { 
  border-top: solid 2px #fd2d3a;
  background-color: #383838;
  color: white;
  padding-bottom: 50px;

  h6  { 
    font-weight: 600;
  }

  a {
    color: #d1d1d1;
    font-weight: 300; 
  }

  .row .sec{
    margin: 20px 25px; 
  }

  .soc div{
    margin-right: 0px;
  }

  @media(max-width: map-get($grid-breakpoints, 'sm')){
    text-align: center;

    .row .sec{
      margin: 30px 0px; 
    }

    .sec{
      width: 100%;
    }

    .soc{
      width: 100%;
      margin-left: 0px;
    }
  }
} 
/** End Footer Styles **/

.tooltip{
  pointer-events: none;
}

.fa-1-5x {
  transform: scale(1.5, 1.5);
}

.rounded {
  border-radius: .5rem !important;

  &-top {
    border-top-left-radius: .5rem !important;
    border-top-right-radius: .5rem !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

    &-bottom {
      border-bottom-left-radius: .5rem !important;
      border-bottom-right-radius: .5rem !important;
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }

}

.rounded-right-pill {
  border-top-right-radius   : 50vh !important;
  border-bottom-right-radius: 50vh !important;
}

.rounded-left-pill {
  border-top-left-radius   : 50vh !important;
  border-bottom-left-radius: 50vh !important;
}

.nav {
  display: block;
}

.nav-pills .nav-link {
  border-radius: 8px !important;

  &.active {
    background-color: $light !important;
    font-weight: bold;
  }

}



.divider {
  margin : 8px 0px;
  height : 1px;
  opacity: 0.5;
  width  : 100%;
}

.shadow {
  box-shadow: 0px 2px 6px 2px transparentize($color: $dark, $amount: 0.90) !important;
}

.post-area {
  background-color: $input-bg;
  color           : $input-color;
  font-size       : $input-font-size;
  font-family     : $input-font-family;
  width           : 100%;
  min-height      : $input-height;


  &:focus,
  &:active {
    outline         : none;
    background-color: $input-focus-bg;
    border          : $input-border-width solid $input-focus-border-color;
    box-shadow      : $input-focus-box-shadow;
    transition      : $input-transition;
  }
}

.scroll-detecting-container {
  height: calc(100vh - 94px);

  @media (min-width: 992px) {
    height: calc(100vh - 44px);
  }
}

.welcome-overlay{
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
 // background-color: rgba(55,0,0,0.5); 
  cursor: pointer;
  background-image: url('../assets/images/defaults/background/welcomebg576x768.png');
  background-position: center;
  background-size: cover;
  z-index: 3;
} 

.welcome-ol-form{
  position: absolute;
  top: 50%;
  left: 50%; 
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  
}

.card.comment{
  position: relative;
  /*z-index: 2;*/

  &>.card-body{
    /*z-index: 2;*/
  }

  &::before{
    content: '';
    position: absolute;
    background-color: $white;
    top: 9px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    z-index: 0;
  }
}

.full-height{
  min-height: calc(100vh - 90px);
  @media(min-width: map-get($grid-breakpoints, 'lg')){
    min-height: calc(100vh - 45px);
  }
}
.page {
  margin-left: unset;
  padding: 50px 0px;
  @media(min-width: map-get($grid-breakpoints, 'lg')){
    margin-left: 80px;
  }
}

.navigation {
  z-index: 1031;
  bottom: 0;
  left: 0;
  width: 100%;

  @media(min-width: map-get($grid-breakpoints, 'lg')){
    width: 80px;
    height: 100vh;
  }

}

.notes {
  position: fixed;
  width: 100%;
  z-index: 1080;

  @media(min-width: map-get($grid-breakpoints, 'lg')){
    width: calc(100% - 250px);
  }

}

.header {
  height: 50px;
  width: 100%;
  z-index: 1030;
  position:fixed;
  top: 0;
  left: 0;

  @media(min-width: map-get($grid-breakpoints, 'lg')){
    left: 80px;
    width: calc(100% - 80px);
  }
}

input,
select,
textarea{
  font-size: $input-font-size;
}

/*** Viewing Post Content ***/
//media styles not loaded
.img-fit-post{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.media-container{
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: repeat(autofit, 1fr);
  gap: 1px;
  grid-auto-flow: dense;
  max-height: 500px;
  &>div{
    position: relative;
    grid-column-end: span 1;  
    grid-row-end: span 1;
    width: 100%;
    height: 100%;
    &>div{
      position: absolute;
      top: 0px; left: 0px; bottom: 0px; right: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
    }
  }
}

.multi-media{
  min-height: 100px;
  /*background-image: url(https://www.fanmire.com/images/app/banner.png);*/
  max-width: 100%;
} 

.media-max{
  grid-column-end: span 4 !important;
  max-height: 500px;
  object-fit: contain;
}

.media-1st-h{
  grid-column-end: span 3 !important;
}

.media-double{
  grid-column-end: span 2 !important;
}

.post-hidden{
  visibility: collapse !important;
  display: none !important;
}
.h-0{
  height: 0px;
}

.post-info {
  font-size: 10px;
  color: #383838;
  font-weight: lighter;
}

.feed-container-area{
  max-width: 500px;
}

.post-view-content{ 
  max-width: 100%;

  img {
    max-width: 100% !important;
    height: auto;
  } 
  
} 

.btn-icon{
  padding: 3px;
}


editor {
  height: 100%;
}

/*Container, container body, iframe*/
.mce-tinymce, .mce-container-body, #code_ifr {
  min-height: 100% !important;
}
/*Container body*/
.mce-container-body {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
/*Editing area*/
.mce-container-body .mce-edit-area iframe {
  position: absolute;
  top: 69px;
  bottom: 37px;
  left: 0;
  right: 0;
}
/*Footer*/
.mce-tinymce .mce-statusbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
 
/* text editor area display on mobile */
.tox {
  .tox-edit-area{
      @media (max-width: 600px) {
          height:300px; 
      }
  }

  .tox-sidebar-wrap{
      @media (max-width: 600px) {
          display: block;
          height:300px; 
      }
  }
}

.feed-round-small {
  border-top-left-radius:8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.feed-round {
  border-top-left-radius:22px;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  .multi-media {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }

  .carousel {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .car-container {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  img {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }

  video {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
  }
}

.introjs-tooltipbuttons {
  border-top: none !important;

  .introjs-button {
    border: none !important;
  }

  .introjs-button {
    border: none !important; 
  }

  .introjs-nextbutton{
    //background-color: 
    color: $primary !important;  
    text-shadow: 0 0 black !important;
  }
}

.introjs-skipbutton {
  padding: 5px !important;
}

.introjs-disableInteraction{
  background-color: $primary !important;
  opacity: 0.1 !important;
}

.card-footer{
  border: none !important;
}

.dropdown-item:hover {
  background: white !important;
  font-weight: bold;
}

.modal-body{
  overflow-y: auto;
}

input:focus, textarea:focus {
  outline: none;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%) !important;
}

textarea.clamp-3 {
  flex-grow: 1;
  border: none;
  resize: none;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 36px;
  max-height: 108px;

}

.custom-content-section {
  max-width: 100%;   

  img{
      max-width: 100%;
  
      @media (max-width: 600px) {
          max-width: 300px; 
      }
  }
}
 
/* Importing Bootstrap SCSS file. 
@import 'bootstrap/scss/bootstrap';
*/

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
