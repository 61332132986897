$primary : #FD2D3A;
$primary-light : #FFE9EB;
$secondary: #510A6F;
$secondary-light : #E4DDE7;
$light : #f1f1f1;
$gray : #E1E1E1;
$darkgray : #757575;
$dark    : #383838;
$success : #2AFF70;
$danger  : #D0342C;
$warning: #FF7900;
$safety: #EED202;
$info : #2ABAFF;
$purple: #510a6f;
$fmgray: #757575;
$fmdarkgray: #636363;
$fmdeepgray: #383838;
$gold: #ffd700;
$silver: #C0C0C0;
$bronze: #CD7F32;

a.text-dark.linkable {
    color: $dark ;



    &:hover {
        color: $primary !important;
        text-decoration: underline !important;
        

        & > *, & > p {
            font-weight: bold !important;
        }

    }

}

.text-medium {
    color: $fmdarkgray !important;
}

.text-secondary {
    color: $secondary !important;
}

.text-gold {
    color: $gold !important;
}

.border-bottom {
    &-thick {
        border-bottom: 1px solid #dee2e6 !important;
        border-bottom-width: 4px !important;
    }
    &-gold {
        border-bottom-color: $gold !important;
    }
    &-silver  {
        border-bottom-color: $silver !important;
    }
    &-bronze  {
        border-bottom-color: $bronze !important;
    }
    
    
}

.border-gold {
    border-color: $gold !important;
}
.border-silver  {
    border-color: $silver !important;
}
.border-bronze  {
    border-color: $bronze !important;
}

.border-spinner {
    --border-size: 0px;
    --border-angle: -1turn;
    width: 60vmin;
    height: 50vmin;
    background-size: calc(100% - var(--border-size) * 2) calc(100% - var(--border-size) * 2), cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 3s linear infinite;
    padding: 3px;

    &.spinner-gold {
        background-image: conic-gradient(from var(--border-angle), $gold, white, $gold);
    }

    &.spinner-silver {
        background-image: conic-gradient(from var(--border-angle), $silver, white, $silver);
    }

    &.spinner-bronze {
        background-image: conic-gradient(from var(--border-angle), $bronze, white, $bronze);
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
  
    & > * {
        border-radius: 50%;
    }
}

@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

.border-thick {
    border-width: 3px !important;
}

.text-silver {
    color: $silver !important;
}

.text-bronze {
    color: $bronze !important;
}

.text-super {
    font-size: .5em;
    vertical-align: super;
}

.text-shadow {
    text-shadow: 1px -1px 3px rgb(0 0 0 / 30%);
}

.text-safe {
    color: $safety;
}

.badge-primary-light {
    background-color: $primary-light;
    color: $primary;
}

.badge-secondary-light {
    background-color: $secondary-light;
    color: $secondary;
}

.bg-secondary-light {
    background-color: $secondary-light;
    color: $secondary;
}

.bg-medium {
    background-color: $fmgray !important;
}

.border-medium {
    border-color: $darkgray !important;
}

.border-primary-light {
    border-color: $primary-light !important;
}

.border-secondary-light {
    border-color: $secondary-light !important;
}

