.header{
  position: -webkit-sticky;
  position: sticky;
  top:0px;
  right: 0px;
  // padding: $input-padding-y $input-padding-x;
  height: 45px;
  z-index: 1000;
}

.sidebar {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  bottom: 0px;
  height: 45px;
  width : 100vw;
  z-index: 1060;

  @media(min-width:map-get($grid-breakpoints, 'lg')) {
    top: 0px;
    bottom: unset;
    min-height: 100vh;
    width     : 230px;
    padding   : $input-padding-y $input-padding-x;
  }

  .sidebar-menu {
    height: 100%;

    .btn {
      flex-grow: 1;
    }

    button i.fa {
      transform: scale(1.5);
    }

    @media(min-width:map-get($grid-breakpoints, 'lg')) {
      height: auto;
      
      .btn {
        text-align: left;
      }

      button i.fa {
        transform: scale(1);
      }
    }
  }
}

button.image-overlay{
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  opacity: 0;
  transition: all 0.1s ease-in;
  &:hover{
    background-color: #00000099;
    opacity: 1;
  }
  &:active{
    background-color: #000000FF;
  }
  &:focus,&:active{
    outline: none;
  }
}