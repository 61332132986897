*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track-piece {
  background-color: transparentize($color: $light, $amount: 0.5);
  border-radius   : $border-radius;
}

*::-webkit-scrollbar-track {
  padding-left : 1px;
  padding-right: 1px;
}
// ::-webkit-scrollbar-button{
//   background-color: $dark;
// }

::-webkit-scrollbar-thumb {
  background-color: lighten($primary, 16%);
  border-radius   : $border-radius;

  &:hover {
    background-color: $primary;
  }
}

// ::-webkit-scrollbar-corner{
//   background-color: $dark;
// border-bottom-right-radius: 50vh;
// }
