.tile-grid-responsive {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    grid-gap: .5em;

    
}

.span-row-2 {
    grid-row: auto/ span 2;
}
.span-col-2 {
    grid-column: auto/ span 2;
}

.grid {
    &-gap {
        gap: .5em;
        &-1 {
            gap: 1em;
        }
        &-2 {
            gap: 1.5em;
        }
    }
    display: grid;
    &-row {
        &-gap {
            row-gap: .5em;
            &-1 {
                row-gap: 1em;
            }
            &-2 {
                row-gap: 1.5em;
            }
        }

        &-span {
            &-2 {
                grid-row: auto/ span 2;
            }
            &-3 {
                grid-row: auto/ span 3;
            }
            &-4 {
                grid-row: auto/ span 4;
            }
            &-0 {
                grid-row: auto/ auto;
            }
        }
        
    }
    &-col {

        &-gap {
            column-gap: .5em;
            &-1 {
                column-gap: 1em;
            }
            &-2 {
                column-gap: 1.5em;
            }
        }

        &-span {
            &-2 {
                grid-column: auto/ span 2;
            }
            &-3 {
                grid-column: auto/ span 3;
            }
            &-4 {
                grid-column: auto/ span 4;
            }
            &-0 {
                grid-column: auto/ auto;
            }
        }
    }
}

.tile-grid-square { 
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-columns: 1fr 1fr;
    grid-auto-rows: 1fr 1fr;
    grid-gap: .5em;
    grid-auto-flow: column dense;
    grid-template-areas:
      ". ."
      ". .";
}
  