.video-js .vjs-bg-opacity{
  opacity: 0;
}

.video-js .vjs-bg-color{
  background-color: $primary;
}

.video-js .vjs-big-play-button{
  background-color: $secondary !important;
}
.video-js .vjs-big-play-button:focus, 
.video-js:hover .vjs-big-play-button{
  background-color: $primary !important;
}