@import 'colors';

.btn {
    &.fm-link{
        &:hover{
            background-color: $light;
        }
    }

    &.subscribe{
        width: 250px;
    }
    
}

.btn {
  position: relative;
  overflow: hidden;
  outline: none;
  padding: .625rem 1.25rem;
  border-radius: .5rem;
  border: none;
  line-height: 1.5rem;
  font-size: .92rem;
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;
  transition: background-color .3s ease, color .3s ease, opacity .3s ease;

  &[disabled] {
    cursor: not-allowed;
  }

  &:focus, &:active{
    box-shadow: none !important;
  }

  &.dense {
      padding: 0.375rem 0.5rem !important;
      font-size: 1rem;
  }

  &.dense > *, &.dense > span, &.dense  > i {
    line-height: 22px !important;
  }

  &.icon {
    display: inline-flex;
    justify-content: center;
    padding: .625rem .625rem;

    &> span, i {
      vertical-align: middle;
      margin-right: 4px;
      line-height: 24px;
      
    }

    &:hover > :first-child {
      text-decoration: none !important;
    }

    &> :first-child {
      margin-left: 8px;     
      
    }

    &> :last-child {
      margin-right: 8px;
    }

    &> :only-child {
      margin: 0px;
    }
    /* .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active */
    &:not(:disabled):not(.disabled).active:focus {
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    }
  }

}

.btn-primary {
  background-color:$primary-light;
  color: $primary;
  border: none;
  
  &:hover, &.active {
      background-color:$primary; 
      color: white;
  }

  &-solid {
    background-color: $primary;
    color: white;

    &:hover,
    &.active {
      background-color: $primary;
      color: white;
    }

  }
    
  &-outline {
      background-color: white; 
      color: $primary;
      border: 1px solid $primary !important;
      
    &:hover, &.active {
        background-color: $primary; 
        color: white;
    }
      
  }
    
  &-alt {
      background-color: transparent; 
      color: $primary;
      
    &:hover, &.active {
        background-color: $primary-light; 
        color: $primary;
    }
      
  }
    
}

.btn-secondary {
  background-color: $secondary-light;
  color: $secondary;
  border: none;

  &:hover {
    background-color: $secondary;
    color: white;
  }

  &-outline {
    background-color: white;
    color: $secondary;
    border: 1px solid $secondary-light  !important;

    &:hover {
      background-color: $secondary;
      color: white;
    }

  }

  &-alt {
    background-color: white;
    color: $secondary;

    &:hover {
      background-color: $secondary-light;
      color: $secondary;
    }

  }

}

.btn-light {
  background-color: $light;
  color: $dark;
  border: none;

  &:hover {
    background-color: $gray;
    color: $dark;
  }

  &-outline {
    background-color: white;
    color: $dark;
    border: 1px solid $gray !important;

    &:hover {
      background-color: $gray;
      color: $dark;
    }

  }

  &-alt {
    background-color: white;
    color: $darkgray;

    &:hover {
      background-color: white;
      color: $dark;
      
    }

  }

}

.btn-dark {
  background-color: $dark;
  color: white;
  border: none;

  &:hover {
    background-color: $darkgray;
    color: white;
  }

  &-outline {
    background-color: white;
    color: $dark;
    border: 1px solid $dark  !important;

    &:hover {
      background-color: $gray;
      color: $dark;
    }

  }

  &-alt {
    background-color: white;
    color: $darkgray;

    &:hover {
      background-color: white;
      color: $dark;
      
    }

  }

}



