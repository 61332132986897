.text-underline {
    text-decoration: underline !important;
}

.text-elipce {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
}

.premium_label {
    border-radius: 2px !important;
}

.font10{
    font-size: 10px !important;
}
.font12{
    font-size: 12px !important;
}

.font14{
    font-size: 14px !important;
}

.font16{
    font-size: 16px !important;
}

.font18{
    font-size: 18px !important;
}

.font20{
    font-size: 20px !important;
}

.font22{
    font-size: 22px !important;
}

.font24{
    font-size: 24px !important;
}

.font28{
    font-size: 28px !important;
}

.font36{
    font-size: 36px !important;
}