.img-thumbnail{
    background-color: #fff;
    border: 1px solid #dee2e6;
    padding: 0px;
    object-fit: cover;
    max-width: 75px;
    width: 75px;
    height: 75px;
    
    &.xs {
        max-width: 30px;
        width: 30px;
        height: 30px;
    }

    &.sm {
        max-width: 40px;
        width: 40px;
        height: 40px;
    }

    &.lg {  
        max-width: 80px;
        width: 80px;
        height: 80px;
    }

    &.xl{  
        max-width: 120px;
        width: 120px;
        height: 120px;
    }

    &.full-width{
        max-width: 100%;
        width: auto;
        height: auto;
    }

    &.bezel {
        padding: 0.25rem;
    }
}