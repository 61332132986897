/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 27, 2018 */

/*switching from poppins to roboto 8/31/22
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');
*/

/*switching from roboto to epilogue / anton 11/12/22 */
@import url('https://fonts.googleapis.com/css2?family=Epilogue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');


*{
    font-family: 'Epilogue';   
}

h3.post-title {
    font-family: 'Anton'; 
    color: #3C3C3C;
    font-weight: 700;
} 

/* poppins local file wasn't compiling  - going with import */
 /*
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
 
*{
    font-family: 'Poppins', sans-serif;   
}
    

@font-face {     
    font-family: "Poppins-Black";
    src: local("Poppins-Black"), url("/src/assets/fonts/Poppins-Black.ttf") format("truetype");

    font-weight: normal;
    font-style: normal;
}

@font-face { 
    font-family: "Poppins-Regular";
    src: local("Poppins-Regular"), url("/src/assets/fonts/Poppins-Regular.ttf") format("truetype");

    font-weight: normal;
    font-style: normal;

}
*/